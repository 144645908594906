import React, { Component } from "react";
import { dateRange } from "../../helper";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import OutsideClick from "detect-outside-click-react";
import "./daterangefilter.scss";

interface Props {
  fetchData: (range: any, type: string) => any;
}

class DateRangeFilter extends Component<
  Props,
  { range: any; clickedCustom: boolean; selectedFilter: string }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      range: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
      clickedCustom: false,
      selectedFilter: "weekly",
    };
  }

  handleSelect = (range) => {
    console.log(range.selection);
    this.setState({ range: range.selection });
  };

  getActiveClass = (classname) => {
    return this.state.selectedFilter === classname ? "text-primary" : "";
  };

  fetchData = (totalDay, rangeType) => {
    this.props.fetchData(dateRange(totalDay), rangeType);
    this.setState({ selectedFilter: rangeType });
  };

  render() {
    return (
      <React.Fragment>
        <div className="btn-group" role="group" aria-label="Basic example">
          <button
            type="button"
            className={`btn btn-link ${this.getActiveClass("weekly")}`}
            onClick={() => {
              this.fetchData(7, "weekly");
            }}
          >
            Weekly
          </button>
          <button
            type="button"
            className={`btn btn-link ${this.getActiveClass("monthly")}`}
            onClick={() => {
              this.fetchData(30, "monthly");
            }}
          >
            Monthly
          </button>
          <button
            className={`btn btn-link dropdown-toggle ${this.getActiveClass(
              "custom"
            )}`}
            type="button"
            onClick={() =>
              this.setState({ clickedCustom: !this.state.clickedCustom })
            }
          >
            Custom
          </button>
        </div>
        <div
          className={`dropdown-menu-custom ${
            this.state.clickedCustom ? "d-block" : "d-none"
          }`}
          aria-labelledby="dropdownMenuButton"
        >
          <OutsideClick close={() => {this.setState({clickedCustom: false})}}>
            <DateRangePicker
              ranges={[this.state.range]}
              onChange={this.handleSelect}
            />
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-link text-danger"
                onClick={() => {
                  this.setState({ clickedCustom: false });
                  this.fetchData(7, "weekly");
                }}
              >
                Reset
              </button>
              <button
                className="btn btn-link text-primary"
                onClick={() => {
                  this.setState({
                    clickedCustom: false,
                    selectedFilter: "custom",
                  });
                  this.props.fetchData(this.state.range, "custom");
                }}
              >
                Done
              </button>
            </div>
          </OutsideClick>
        </div>
      </React.Fragment>
    );
  }
}

export default DateRangeFilter;
