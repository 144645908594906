import React, { Component } from "react";
import axios from "axios";
import { apiPrefix, baseTitle, dateRange } from "../../helper";
import { Helmet } from "react-helmet";
import "react-open-weather/lib/css/ReactWeather.css";
import { connect } from "react-redux";
import Layout from "./../Layout";
import { logoutUser } from "../login/actions/authActions";
import ReactApexChart from "react-apexcharts";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./analytics.scss";
import DateRangeFilter from "./../daterangefilter/DateRangeFilter";
import ReactLoading from "react-loading";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
}

const TITLE = `${baseTitle} Analytics`;

class Analytics extends Component<
  Props,
  {
    chart: any;
    top_flowsOptions: any;
    top_flowsSeries: any;
    userActivityChart: any;
    selectedMsgRange: string;
    selectedUserRange: string;
    loadingMsgActivity: boolean;
    loadingUserActivity: boolean;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chart: {},
      userActivityChart: {},
      top_flowsOptions: [],
      top_flowsSeries: [],
      selectedMsgRange: "weekly",
      selectedUserRange: "weekly",
      loadingMsgActivity: true,
      loadingUserActivity: true,
    };
    this.fetchCharts(dateRange(7), "weekly");
    this.fetchUserChart(dateRange(7), "weekly");
    this.getTopFlows();
    console.log();
  }

  getTopFlows = async () => {
    try {
      let url = apiPrefix + "top-flows";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`,
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers,
      });
      console.log("Top flows response", response);
      if (response.data.success) {
        let top_flowsOptions = [];
        let top_flowsSeries = [];
        response.data.data.top_flows.map((item) => {
          top_flowsOptions.push(item.name);
          top_flowsSeries.push(item.count);
        });
        this.setState({ top_flowsOptions, top_flowsSeries });
        console.log("Top flows", top_flowsOptions, top_flowsSeries);
      }
    } catch (error) {
      console.log(error);
    }
  };

  getOptions = (labels, colors, stroke = false, labelType = "datetime") => {
    let options = {
      chart: {
        id: "apexchart-example",
        foreColor: "black",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        show: stroke,
        // curve: "smooth",
        // lineCap: "butt",
        // colors: strokeColor,
        // width: 2,
        // dashArray: 0
      },
      fill: {
        // colors: colors,
        opacity: 0.9,
        type: "gradient",
        gradient: {
          // shade: 'dark',
          type: "horizontal",
          shadeIntensity: 0.5,
          // gradientToColors: colors,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
        image: {
          src: [],
          width: undefined,
          height: undefined,
        },
        pattern: {
          style: "verticalLines",
          width: 6,
          height: 6,
          strokeWidth: 2,
        },
      },
      xaxis: {
        type: labelType,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: true,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
          datetimeUTC: true,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
      },
      labels,
    };
    return options;
  };

  getSeries = (data, seriesName = null) => {
    let series = [
      {
        name: seriesName,
        data,
      },
    ];
    return series;
  };

  fetchCharts = async (range, type) => {
    try {
      this.setState({ selectedMsgRange: type, loadingMsgActivity: true });
      let startDate: any = range.startDate.getTime() / 1000;
      let endDate: any = range.endDate.getTime() / 1000;
      console.log("fetchhhhhhhhhhhh ", parseInt(startDate), parseInt(endDate));

      let url =
        apiPrefix +
        `line-graph/message-activity/${parseInt(startDate)}/${parseInt(
          endDate
        )}`;
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`,
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers,
      });
      console.log("Charts response message", response.data.data.lineGraph);
      if (response.data) {
        this.setState({
          chart: response.data.data.lineGraph,
          loadingMsgActivity: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };

  fetchUserChart = async (range, type) => {
    try {
      this.setState({ selectedUserRange: type, loadingUserActivity: true });
      let startDate: any = range.startDate.getTime() / 1000;
      let endDate: any = range.endDate.getTime() / 1000;
      console.log("fetchhhhhhhhhhhh ", parseInt(startDate), parseInt(endDate));

      let url =
        apiPrefix +
        `line-graph/user-activity/${parseInt(startDate)}/${parseInt(endDate)}`;
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`,
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers,
      });
      console.log(
        "Charts response userActivityChart",
        response.data.data.lineGraph
      );
      if (response.data) {
        this.setState({
          userActivityChart: response.data.data.lineGraph,
          loadingUserActivity: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };

  getActiveRangeText = (range) => {
    let text;
    switch (range) {
      case "weekly":
        text = "Last seven days";
        break;
      case "monthly":
        text = "Last thirty days";
        break;
      case "custom":
        text = "custom";
        break;
      default:
        break;
    }

    return text;
  };

  render() {
    return (
      <React.Fragment>
        <Layout {...this.props}>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
          <div className="full-height" style={{ paddingTop: 60 }}>
            {this.state.chart && (
              <div className="p-3 col-md-12 mt-2 mr-2">
                <div className="card p-2">
                  <div className="d-flex justify-content-between mt-2">
                    <div className="pl-2 ml-2">
                      <h5 className="global_title_msf mb-0">
                        Message Activity
                      </h5>
                      <p style={{ fontSize: 12 }}>
                        {this.getActiveRangeText(this.state.selectedMsgRange)}
                      </p>
                    </div>
                    <div className="row">
                      {this.state.loadingMsgActivity && (
                        <ReactLoading
                          className=""
                          type={"bubbles"}
                          color={"#007bff"}
                          height={35}
                          width={35}
                        />
                      )}
                      <DateRangeFilter fetchData={this.fetchCharts} />
                    </div>
                  </div>
                  <div className="">
                    {/* <LineChart chart={this.state.chart} /> */}
                    <ReactApexChart
                      options={this.getOptions(
                        this.state.chart.hasOwnProperty("labels")
                          ? this.state.chart.labels
                          : [],
                        ["#546E7A", "#E91E63"],
                        true
                      )}
                      series={this.getSeries(
                        this.state.chart.hasOwnProperty("datasets")
                          ? this.state.chart.datasets[0].data
                          : [],
                        this.state.chart.hasOwnProperty("label")
                          ? this.state.chart.datasets[0].label
                          : ""
                      )}
                      type="area"
                      height={320}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.userActivityChart && (
              <div className="p-3 col-md-12 mt-2 mr-2">
                <div className="card p-2">
                  <div className="d-flex justify-content-between mt-2">
                    <div className="pl-2 ml-2">
                      <h5 className="global_title_msf mb-0">User Activity</h5>
                      <p style={{ fontSize: 12 }}>
                        {this.getActiveRangeText(this.state.selectedUserRange)}
                      </p>
                    </div>
                    <div className="row">
                      {this.state.loadingUserActivity && (
                        <ReactLoading
                          className=""
                          type={"bubbles"}
                          color={"#007bff"}
                          height={35}
                          width={35}
                        />
                      )}
                      <DateRangeFilter fetchData={this.fetchUserChart} />
                    </div>
                  </div>
                  <div className="">
                    {/* <LineChart chart={this.state.chart} /> */}
                    <ReactApexChart
                      options={this.getOptions(
                        this.state.userActivityChart.hasOwnProperty("labels")
                          ? this.state.userActivityChart.labels
                          : [],
                        ["#14b5ab", "#E91E63"]
                      )}
                      series={this.getSeries(
                        this.state.userActivityChart.hasOwnProperty("datasets")
                          ? this.state.userActivityChart.datasets[0].data
                          : [],
                        this.state.userActivityChart.hasOwnProperty("label")
                          ? this.state.userActivityChart.datasets[0].label
                          : []
                      )}
                      type="area"
                      height={320}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.top_flowsOptions && this.state.top_flowsSeries && (
              <div className="p-3 col-md-12 mt-2 mr-2">
                <div className="card p-2">
                  <h5 className="ml-2 p-2 global_title_msf">Top Flows</h5>
                  <div className="">
                    <ReactApexChart
                      options={this.getOptions(
                        this.state.top_flowsOptions,
                        ["#546E7A", "#E91E63"],
                        true,
                        "category"
                      )}
                      series={this.getSeries(
                        this.state.top_flowsSeries,
                        "Total"
                      )}
                      type="bar"
                      height={320}
                    />
                  </div>
                </div>
              </div>
            )}
            {!this.state.chart && <span>Loading...</span>}
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.currentUserToken,
});

export default connect(mapStateToProps, { logoutUser })(Analytics);
