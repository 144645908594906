import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "../video/video.scss";
import ReactTyped from "react-typed";
import ReactPlayer from "react-player";

interface Props {
  item: any;
  addOrUpdateFlow: any;
  allItems: any;
  index: number;
  flows: any;
}

class Video extends React.Component<
  Props,
  {
    video: any;
    sendBlockClicked: boolean;
    phnNmbrClicked: boolean;
    buttons: any;
    addbuttonTitle: string | null;
    addbuttonType: string | null;
    addbuttonPayload: string | null;
    addbuttonSendBlock: string | null;
    addbuttonCall: string | null;
    addbuttonUrl: string | null;
    flows: any;
    sendblock: string;
    selectedFlow: string | null;
    btnIndx: number | null;
    urlClicked: boolean;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      video: this.props.item.video ? this.props.item.video : "",
      urlClicked: false,
      sendBlockClicked: false,
      phnNmbrClicked: false,
      buttons: this.props.item.buttons ? this.props.item.buttons : [],
      addbuttonTitle: null,
      addbuttonType: null,
      addbuttonPayload: null,
      addbuttonSendBlock: "",
      addbuttonCall: "",
      addbuttonUrl: "",
      flows: this.props.flows,
      sendblock: "",
      selectedFlow: null,
      btnIndx: null,
    };
  }

  updateVideoBtn = (type, btnIndx = null) => {
    console.log("First response: ", this.props.allItems);
    let allItems: any = [...this.props.allItems];
    let buttons = [...this.state.buttons];
    let item = allItems[this.props.index];

    switch (type) {
      case "add_button":
        let button;
        console.log(
          "addbuttonPayload",
          this.state.addbuttonPayload,
          "addbuttonTitle",
          this.state.addbuttonTitle,
          "addbuttonType",
          this.state.addbuttonType
        );
        if (
          this.state.addbuttonPayload &&
          this.state.addbuttonTitle &&
          this.state.addbuttonType
        ) {
          if (this.state.addbuttonType == "web_url") {
            button = {
              type: "web_url",
              url: this.state.addbuttonPayload,
              title: this.state.addbuttonTitle,
            };
          } else if (this.state.addbuttonType == "phone_number") {
            button = {
              type: "phone_number",
              title: this.state.addbuttonTitle,
              payload: this.state.addbuttonPayload,
            };
          } else if (this.state.addbuttonType == "send_block") {
            button = {
              type: "postback",
              title: this.state.addbuttonTitle,
              payload: this.state.addbuttonPayload,
            };
            // if (!this.state.selectedFlow) {
            //   this.props.addOrUpdateFlow(null, this.state.addbuttonSendBlock);
            // }
          }
          if (this.state.btnIndx != null) {
            buttons[this.state.btnIndx] = button;
            this.setState({ btnIndx: null });
          } else {
            buttons.push(button);
          }
          this.setState({ buttons, selectedFlow: null });
          item.buttons = buttons;
          console.log("New buttons", buttons);
          console.log("selected btn indx: ", this.state.btnIndx);
        } else {
          toast.error("Please add button title and payload");
        }
        break;
      case "delete_button":
        console.log("delete btn index ", btnIndx);
        buttons.splice(btnIndx, 1);
        item.buttons = buttons;
        this.setState({ buttons });
        console.log("New buttons", buttons);
        break;
    }
    allItems[this.props.index] = item;
    console.log("all items after updating text", allItems);
    this.props.addOrUpdateFlow(allItems);
  };

  submitVideo = () => {
    if (this.state.video) {
      let allItems: any = [...this.props.allItems];
      let videoFile = allItems[this.props.index];
      videoFile.video = this.state.video;
      allItems[this.props.index] = videoFile;
      console.log(allItems);
      this.props.addOrUpdateFlow(allItems);
    } else {
      toast.error("Please add video url or upload an video");
    }
  };

  filterFlows = (e) => {
    let updateList = this.props.flows;
    updateList = updateList.filter((item) => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });

    this.setState({
      flows: updateList,
      addbuttonType: "send_block",
      // addbuttonPayload: e.target.value,
      addbuttonSendBlock: e.target.value,
      addbuttonCall: null,
      addbuttonUrl: null,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="move text box_style video append_box video_animation"
          id="video"
        >
          <div
            data-toggle="modal"
            data-target={"#videoModal" + this.props.item.id}
          >
            <input
              type="file"
              className="fileuploader"
              style={{ display: "none" }}
            />
            <button className="fileuploader-btn">Select a Video File</button>
            <br />
            {/*<i className="fa fa-youtube-play" aria-hidden="true"></i>*/}
            <img src="assets/video.png" />
            <p style={{ marginBottom: 30 }}>+Video</p>
          </div>

          <div
            className="delet_icon_gallery"
            id="gallery_delete"
            onClick={() =>
              this.props.addOrUpdateFlow(
                this.props.allItems.filter(
                  (item) => item.id !== this.props.item.id
                )
              )
            }
          >
            <i className="fa fa-trash-o" aria-hidden="true"></i>
          </div>
          <div className="videoPlayer">
            <ReactPlayer
              muted
              controls={true}
              url={this.props.item.video}
              playing={false}
              height={"100%"}
              width={"100%"}
            />
          </div>
          <div className="button_para new_btn_border" id="text_box">
            {this.state.buttons.length > 0 &&
              this.state.buttons.map((button, index) => {
                if (button) {
                  return (
                    <div className="box_button new_box_border" key={index}>
                      <button
                        type="button"
                        className="delete_box_flex box_button_input button_padding_5"
                        id="sub_box_button_input_1"
                      >
                        <span
                          onClick={() =>
                            this.setState({
                              btnIndx: index,
                              addbuttonTitle: button.title,
                              addbuttonCall:
                                button.type == "phone_number"
                                  ? button.payload
                                  : null,
                              addbuttonUrl:
                                button.type == "web_url" ? button.url : null,
                              addbuttonSendBlock:
                                button.type == "postback"
                                  ? button.payload
                                  : null,
                              addbuttonPayload: button.payload
                                ? button.payload
                                : button.url,
                              addbuttonType: button.type,
                              sendBlockClicked:
                                button.type == "postback" ? true : false,
                            })
                          }
                          className="modal_button"
                          data-toggle={"modal"}
                          data-target={"#text_modal" + this.props.item.id}
                        >
                          {button.title}
                        </span>
                        <div
                          className="sub_delete_box"
                          onClick={() =>
                            this.updateVideoBtn("delete_button", index)
                          }
                        >
                          <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </div>
                      </button>
                    </div>
                  );
                }
              })}
            {this.state.buttons.length < 3 && (
              <div
                className="box_button"
                onClick={() => {
                  this.setState({
                    addbuttonTitle: "",
                    addbuttonCall: "",
                    addbuttonUrl: "",
                    addbuttonSendBlock: "",
                    addbuttonPayload: "",
                  });
                }}
              >
                <button
                  type="button"
                  className="delete_box_flex box_button_input"
                  id="sub_box_button_input_1"
                  onClick={() => this.setState({ btnIndx: null })}
                >
                  <span
                    className="modal_button"
                    data-toggle={"modal"}
                    data-target={"#text_modal" + this.props.item.id}
                  >
                    +Add Button
                  </span>
                </button>
              </div>
            )}
            <div
              className="delet_icon_text"
              id="text_delete"
              onClick={() =>
                this.props.addOrUpdateFlow(
                  this.props.allItems.filter(
                    (item) => item.id !== this.props.item.id
                  )
                )
              }
            >
              <i className="fa fa-trash-o" aria-hidden="true"></i>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id={"videoModal" + this.props.item.id}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Upload video
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <ReactTyped
                    // typedRef={typedRef()}
                    loop={true}
                    loopCount={0}
                    typeSpeed={100}
                    startDelay={0}
                    backSpeed={50}
                    backDelay={2}
                    strings={[
                      
                      "From Facebook: Upload a video to your profile", 
                      "or page, get the video link",
                      "https://facebook.com/<page_id>/videos/<id>",
                      " and paste it here.",
                      "From Cloud Storage: Upload a video to cloud",
                      "storage, obtain the direct download link",
                      "and paste it here. Max file size is 20 MB.",
                      "Supported format is .MP4.",
                    ]}
                    stopped={null}
                    smartBackspace
                    shuffle={false}
                    fadeOut={false}
                    fadeOutDelay={100}
                    attr="placeholder"
                    bindInputFocusEvents={false}
                  >
                    <input
                      onChange={(e) => this.setState({ video: e.target.value })}
                      value={this.state.video}
                      type="text"
                      className="form-control modal_input"
                      placeholder="From Facebook: Upload a video to your profile or page, get the video link and paste it here."
                    />
                  </ReactTyped>
                </div>
                {/* <p className="or">OR</p>
                <div className="video_upload_file">
                  <input type="file" className="Modal_fileuploader" />
                  <img src="assets/upload.png" />
                  <p>Upload File</p>
                </div> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => this.submitVideo()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id={"text_modal" + this.props.item.id}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Button</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="button_title">
                  <p>button Title:</p>
                  <input
                    value={this.state.addbuttonTitle}
                    type="text"
                    placeholder="Button title"
                    className="button_title_input modal_input"
                    id="buttton"
                    onChange={(e) =>
                      this.setState({ addbuttonTitle: e.target.value })
                    }
                  />
                </div>
                <div className="button_title_body">
                  <p>When This Button is Pressed</p>
                  <div
                    className={`${
                      this.state.sendBlockClicked ||
                      this.state.addbuttonSendBlock
                        ? "HIDE "
                        : ""
                    } button_box border_all`}
                    onClick={() => {
                      this.setState({
                        sendBlockClicked: true,
                        flows: this.props.flows,
                      });
                    }}
                  >
                    <img
                      className="padding_top_icon"
                      src="assets/flow_name.png"
                    />
                    <h5>Flow Name</h5>
                  </div>

                  {this.state.sendBlockClicked && (
                    <div className="dropdown_bx dropdown-menu">
                      <ul className="">
                        {this.state.flows.map((flow, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              this.setState({
                                selectedFlow: flow.slug,
                                addbuttonSendBlock: flow.name,
                                addbuttonType: "send_block",
                                addbuttonPayload: flow.slug,
                              })
                            }
                          >
                            {flow.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <input
                    data-toggle="dropdown"
                    // ref={this.sendblockInput}
                    value={this.state.addbuttonSendBlock}
                    onChange={(e) => {
                      this.filterFlows(e);
                      this.setState({
                        addbuttonSendBlock: e.target.value,
                        addbuttonType: "send_block",
                        addbuttonPayload: e.target.value
                          .replace(/\s+/g, "_")
                          .toUpperCase(),
                      });
                    }}
                    className={
                      this.state.sendBlockClicked ||
                      this.state.addbuttonSendBlock
                        ? "hide_input"
                        : "hide_input HIDE"
                    }
                    type="text"
                    placeholder="Flow Name"
                    autoFocus
                  />

                  <div className="hide_modal_input button_hide">
                    <input
                      className="hide_input"
                      type="text"
                      placeholder="Flow Name"
                    />
                  </div>
                  <div
                    className={`${
                      this.state.phnNmbrClicked || this.state.addbuttonCall
                        ? "HIDE "
                        : ""
                    } button_box border_all`}
                    onClick={() => this.setState({ phnNmbrClicked: true })}
                  >
                    <img className="padding_top_icon" src="assets/call.png" />
                    <h5>Call</h5>
                  </div>
                  <div className="hide_modal_input button_hide">
                    <input
                      className="hide_input"
                      type="number"
                      placeholder="Call"
                    />
                  </div>

                  <input
                    value={this.state.addbuttonCall}
                    onChange={(e) =>
                      this.setState({
                        addbuttonType: "phone_number",
                        addbuttonPayload: e.target.value,
                        addbuttonCall: e.target.value,
                        addbuttonUrl: null,
                        addbuttonSendBlock: null,
                      })
                    }
                    className={
                      this.state.phnNmbrClicked || this.state.addbuttonCall
                        ? "hide_input"
                        : "hide_input HIDE"
                    }
                    type="number"
                    placeholder="number"
                  />

                  <div
                    className={`${
                      this.state.urlClicked || this.state.addbuttonCall
                        ? "HIDE "
                        : ""
                    } button_box border_all`}
                    onClick={() => this.setState({ urlClicked: true })}
                  >
                    <i
                      className="fa fa-link padding_top_icon"
                      aria-hidden="true"
                    ></i>
                    <h5>Url</h5>
                  </div>
                  <div className="hide_modal_input button_hide">
                    <input
                      className="hide_input"
                      type="number"
                      placeholder="Phone Number"
                    />
                  </div>

                  <input
                    value={this.state.addbuttonUrl}
                    onChange={(e) =>
                      this.setState({
                        addbuttonType: "web_url",
                        addbuttonPayload: e.target.value,
                        addbuttonCall: null,
                        addbuttonUrl: e.target.value,
                        addbuttonSendBlock: null,
                      })
                    }
                    className={
                      this.state.urlClicked || this.state.addbuttonUrl
                        ? "hide_input"
                        : "hide_input HIDE"
                    }
                    type="text"
                    placeholder="url"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary pop_btn"
                  data-dismiss="modal"
                >
                  Close
                </button>

                <button
                  type="button"
                  className="btn btn-primary pop_btn"
                  data-dismiss="modal"
                  onClick={() => this.updateVideoBtn("add_button")}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allItems: state.automation.items,
});

export default connect(mapStateToProps, null)(Video);
