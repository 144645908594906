import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import "./dashboard.scss";
import jwtDecode from "jwt-decode";
import { Sparklines, SparklinesBars } from "react-sparklines";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiPrefix, baseTitle } from "./../../helper";
import LineChart from "./../linechart/LineChart";
import ReactWeather from "react-open-weather";
import "react-open-weather/lib/css/ReactWeather.css";
import { connect } from "react-redux";
import Layout from "./../Layout";
import { logoutUser } from "./../login/actions/authActions";
import Chart from "react-apexcharts";

const TITLE = `${baseTitle} Dashboard`;

const options = {
  legend: {
    display: true,
    position: "bottom"
  }
};
const options1 = {
  legend: {
    display: true,
    position: "bottom"
  }
};
const data = {
  labels: ["Male", "Female"],
  datasets: [
    {
      data: [90, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
    }
  ]
};
const data1 = {
  labels: ["Male", "Female"],
  datasets: [
    {
      data: [300, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
    }
  ]
};

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
}

interface Overview {
  readonly title: string;
  readonly count: number;
  readonly bar: string;
}

class Dashboard extends Component<
  Props,
  {
    overviews: [] | null;
    top_flows: [];
    messageActivity: any;
    userActivity: any;
    msgNotRecognizedByAi: [];
    filterMsgActivity: string;
    filterUserActivity: string;
    recentUsers: [];
    genderOptions: any
    languageOptions: any
  }
  > {
  constructor(props: Props) {
    super(props);
    this.state = {
      overviews: [],
      top_flows: [],
      messageActivity: null,
      userActivity: null,
      msgNotRecognizedByAi: [],
      filterMsgActivity: "weekly",
      filterUserActivity: "weekly",
      recentUsers: [],
      languageOptions: {chart: {
        id: "gender -chart-example",
        foreColor: "black",
        toolbar: {
          show: false
        },
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '70%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px'
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: '#111',
              fontSize: '36px',
              show: true,
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['Percent English'],},
      genderOptions: {
        chart: {
          id: "gender -chart-example",
          foreColor: "black",
          toolbar: {
            show: false
          },
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '17px'
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: '#111',
                fontSize: '36px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ABE5A1'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Percent Male'],
      }
    };
    this.fetchOverView();
    this.getTopFlows();
    this.getMesssageActivity();
    this.getUserActivity();
    this.getMsgNotRecognizedByAi();
    this.getRecentUsers();
    // console.log("Jwt decoded token: ", jwtDecode(this.props.token))
  }

  getRecentUsers = async () => {
    try {
      let url = `${apiPrefix}recentUsers`;
      console.log(url);
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      if (response.data.success) {
        let recentUsers = response.data.data.users;
        this.setState({ recentUsers });
        console.log("recent users", recentUsers);
      }
    } catch (error) {
      console.log("Recent users", error);
    }
  };

  getMesssageActivity = async (filter = "weekly") => {
    try {
      let url = `${apiPrefix}messageActivity/${filter}`;
      console.log(url);
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      if (response.data.success) {
        let messageActivity = response.data.data.messageActivity;
        this.setState({ messageActivity, filterMsgActivity: filter });
        console.log("Message activity", response.data.data.messageActivity);
      }
    } catch (error) {
      console.log("User activity", error);
    }
  };

  getUserActivity = async (filter = "weekly") => {
    try {
      let url = `${apiPrefix}userActivity/${filter}`;
      console.log(url);
      console.log("filter useractivity", filter);
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });

      if (response.data.success) {
        let userActivity = response.data.data.userActivity;
        this.setState({ userActivity, filterUserActivity: filter });
        console.log("User activity", response.data.data.userActivity);
      }
    } catch (error) {
      console.log("User activity error: ", error);
    }
  };

  getMsgNotRecognizedByAi = async () => {
    try {
      let url = apiPrefix + "not-recognized";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });

      if (response.data.success) {
        let msgNotRecognizedByAi = response.data.data.messages;
        this.setState({ msgNotRecognizedByAi });
        console.log("Msg Not Recognized By Ai: ", response.data.data.messages);
      }
    } catch (error) {
      console.log("User activity error: ", error);
    }
  };

  getTopFlows = async () => {
    try {
      let url = apiPrefix + "top-flows";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("Top flows response", response);
      if (response.data.success) {
        this.setState({ top_flows: response.data.data.top_flows });
        console.log("Top flows", response.data.data.top_flows);
      }
    } catch (error) { }
  };

  fetchOverView = async () => {
    try {
      let url = apiPrefix + "overview";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("Overview response", response.data);
      if (response.data) {
        this.setState({ overviews: response.data.data.overview });
      }
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Layout {...this.props}>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
          <div
            className="row m-0 full-height row_center"
            style={{ paddingTop: 60 }}
          >
            <div className="main_loop_item">
              {window.scrollTo(0, 0)}
              {this.state.overviews &&
                this.state.overviews.map((item: Overview, index: number) => (

                  <div className="d-flex loop_item" key={index}>
                    <div className="dashboard-card">
                      <div className="card-header">
                        <h4>{item.title}</h4>
                        <h4 className="count">{item.count}</h4>
                      </div>
                      <div className="spark-line x_sparkline_img">
                        {index == 0 ? <img src="assets/todays-user.png" /> : null}
                        {index == 1 ? <img src="assets/total-user.png" /> : null}
                        {index == 2 ? (
                          <img src="assets/total-message.png" />
                        ) : null}
                        {index == 3 ? (
                          <img src="assets/communication.png" />
                        ) : null}
                      </div>
                    </div>
                  </div>

                ))}
            </div>
            <div className="col-md-6 mt_20">
              <div className="msg_activity">
                <div className="activity_message card">
                  <div className="activity_header">
                    <div className="activity_message_title">
                      <div className="active_message_icon">
                        <img src="assets/communication.png" />
                      </div>
                      <div className="activity_message_txt">
                        <h4>Message Activity</h4>
                        <p>
                          Last{" "}
                          {this.state.filterMsgActivity == "weekly"
                            ? "seven"
                            : "thirty"}{" "}
                          days
                        </p>
                      </div>
                    </div>
                    <div className="activity_button">
                      <button
                        type="button"
                        onClick={() => this.getMesssageActivity("weekly")}
                        className={`activity_btn ${
                          this.state.filterMsgActivity == "weekly"
                            ? "msg_active_btn"
                            : "btn-inactive"
                          }`}
                      >
                        Weekly
                      </button>
                      <button
                        type="button"
                        onClick={() => this.getMesssageActivity("monthly")}
                        className={`activity_btn ${
                          this.state.filterMsgActivity == "monthly"
                            ? "msg_active_btn"
                            : "btn-inactive"
                          }`}
                      >
                        Monthly
                      </button>
                    </div>
                  </div>
                  <div className="activity_body">
                    <div className="activity_body_middle">
                      <div className="incoming padding_top">
                        <h5>Incoming</h5>
                        <div className="outgoing_details">
                          <h3 className="margin_top">
                            {this.state.messageActivity &&
                              this.state.messageActivity.currentRangeIncoming}
                          </h3>
                          <p className="percentage">
                            {this.state.messageActivity &&
                              this.state.messageActivity.incomingRatio}
                            <i
                              className={`fa fa-caret-up ${
                                this.state.messageActivity &&
                                  this.state.messageActivity.incomingStatus ==
                                  "positive"
                                  ? "color_green"
                                  : `color_red`
                                }`}
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      </div>
                      <div className="outgoing padding_top">
                        <h5>Outgoing</h5>
                        <div className="outgoing_details">
                          <h3 className="margin_top">
                            {this.state.messageActivity &&
                              this.state.messageActivity.currentRangeOutgoing}
                          </h3>
                          <p className="percentage">
                            {this.state.messageActivity &&
                              this.state.messageActivity.outgoingRatio}{" "}
                            <i
                              className={`fa fa-caret-up ${
                                this.state.messageActivity &&
                                  this.state.messageActivity.outgoingStatus ==
                                  "positive"
                                  ? "color_green"
                                  : `color_red`
                                }`}
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      </div>
                      <div className="total padding_top">
                        <h5>Total</h5>
                        <div className="outgoing_details">
                          <h3 className="margin_top">
                            {this.state.messageActivity &&
                              this.state.messageActivity.totalMessage}
                          </h3>
                          <p className="percentage">
                            {this.state.messageActivity &&
                              this.state.messageActivity.totalMessageRatio}{" "}
                            <i
                              className={`fa fa-caret-up ${
                                this.state.messageActivity &&
                                  this.state.messageActivity.totalStatus ==
                                  "positive"
                                  ? "color_green"
                                  : `color_red`
                                }`}
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="msg_view">
                    <Link to="/analytics/activity">
                      View Message Activity{" "}
                      {/*<i className="fa fa-caret-up" aria-hidden="true"></i>*/}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt_20">
              <div className="msg_activity">
                <div className="activity_message card">
                  <div className="activity_header">
                    <div className="activity_message_title">
                      <div className="active_message_icon">
                        <img src="assets/user.png" />
                      </div>
                      <div className="activity_message_txt">
                        <h4>User Activity</h4>
                        <p>
                          {this.state.filterUserActivity == "weekly"
                            ? "Last seven days"
                            : "Today's"}
                        </p>
                      </div>
                    </div>

                    <div className="activity_button">
                      <button
                        type="button"
                        onClick={() => this.getUserActivity("weekly")}
                        className={`activity_btn ${
                          this.state.filterUserActivity == "weekly"
                            ? "msg_active_btn"
                            : "btn-inactive"
                          }`}
                      >
                        Weekly
                      </button>
                      <button
                        type="button"
                        onClick={() => this.getUserActivity("daily")}
                        className={`activity_btn ${
                          this.state.filterUserActivity == "daily"
                            ? "msg_active_btn"
                            : "btn-inactive"
                          }`}
                      >
                        Daily
                      </button>
                    </div>
                  </div>
                  <div className="activity_body">
                    <div className="activity_body_middle">
                      <div className="incoming padding_top">
                        <h5>New Users</h5>
                        <div className="outgoing_details">
                          <h3 className="margin_top">
                            {this.state.userActivity &&
                              this.state.userActivity.currentUsers}
                          </h3>
                          <p className="percentage">
                            {this.state.userActivity &&
                              this.state.userActivity.userRatio}
                            <i
                              className={`fa fa-caret-up ${
                                this.state.userActivity &&
                                  this.state.userActivity.userStatus == "positive"
                                  ? "color_green"
                                  : `color_red`
                                }`}
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      </div>
                      <div className="outgoing padding_top">
                        <h5>Lifetime Users</h5>
                        <div className="outgoing_details">
                          <h3 className="margin_top">
                            {this.state.userActivity &&
                              this.state.userActivity.totalUsers}
                          </h3>
                          <p className="percentage font-weight-bold pl-1"> -</p>
                        </div>
                      </div>
                      <div className="total padding_top">
                        <h5>Message By Users</h5>
                        <div className="outgoing_details">
                          <h3 className="margin_top">
                            {this.state.userActivity &&
                              this.state.userActivity.currentMessages}
                          </h3>
                          <p className="percentage">
                            {this.state.userActivity &&
                              this.state.userActivity.messageRatio}{" "}
                            <i
                              className={`fa fa-caret-up ${
                                this.state.userActivity &&
                                  this.state.userActivity.messageStatus ==
                                  "positive"
                                  ? "color_green"
                                  : `color_red`
                                }`}
                              aria-hidden="true"
                            ></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="msg_view">
                    <Link to="/analytics/activity">
                      View User Activity{" "}
                      {/*<i className="fa fa-caret-up" aria-hidden="true"></i>*/}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 mt_20">
                            <div className="user-table user_main_table card">
                                <div className="user_table_header">
                                    <h4>User Retention</h4>
                                    <p>Nov 12 -Nov 19 </p>
                                </div>
                                <table className="table table-responsive table-hover N_table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Users</th>
                                            <th>Day 1</th>
                                            <th>Day 2</th>
                                            <th>Day 3</th>
                                            <th>Day 4</th>
                                            <th>Day 5</th>
                                            <th>Day 6</th>
                                            <th>Day 7</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total</td>
                                            <td>4255</td>
                                            <td>4255</td>
                                            <td>4255</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>

                                        </tr>
                                        <tr>
                                            <td>Nov 12</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>23.65%</td>
                                            <td>23.65%</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>

                                        </tr>
                                        <tr>
                                            <td>Nov 13</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>

                                        </tr>
                                        <tr>
                                            <td>Nov 14</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>
                                        </tr>
                                        <tr>
                                            <td>Nov 15</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>
                                        </tr>
                                        <tr>
                                            <td>Nov 16</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="msg_view">
                                    <a href="#">View User Retention <i className="fa fa-caret-up"
                                        aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div> */}
            <div className="col-md-6 ">
              <div className="user-table user_main_table card flow_height">
                <div className="user_table_header_flow">
                  <img src="assets/top-flows.png" />
                  <h4>Top Flows</h4>
                </div>
                <div className="flow_body">
                  <div className="flow_header D_flex">
                    <p>Flows</p>
                    <p className="count_left">Count</p>
                  </div>
                  <div className="flow_scroll">
                    {this.state.top_flows.length > 0 &&
                      this.state.top_flows.map((flow: any, index: number) => {
                        return (
                          <div className="flow_list" key={index}>
                            <span>{flow.name}</span>
                            <span className="float-right">{flow.count}</span>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="msg_view margin_top_7">
                  <a href="#">
                    View Top Flows{" "}
                    {/*<i className="fa fa-caret-up" aria-hidden="true"></i>*/}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="user-table user_main_table card flow_height">
                <div className="user_table_header_flow padding_warning">
                  <img src="assets/warning.png" />

                  <h4>Message Not Recognized By AI</h4>
                </div>
                <div className="flow_body">
                  <div className="flow_header D_flex">
                    <p>Flows</p>
                  </div>
                  <div className="flow_scroll">
                    {this.state.msgNotRecognizedByAi.length > 0 &&
                      this.state.msgNotRecognizedByAi.map(
                        (item: any, index: number) => {
                          return (
                            <div className="flow_list" key={index}>
                              <span>{item.message}</span>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
                <div className="msg_view margin_top_7">
                  <Link to="/retrain">
                    Retrain
                    {/*<i className="fa fa-caret-up" aria-hidden="true"></i>*/}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt_20">
              <div className="card border_none g_bg">
                <div className="user_main_table_header">
                  <img src="assets/user.png" />
                  <h4>Recent Users</h4>
                </div>
                <div className="gadiel_table">
                  <table className="user_table margin_bottom_0 table table-responsive table-hover ">
                    <thead className="T_head">
                      <tr>
                        <th>User</th>
                        <th>Name</th>
                        <th>joining_date</th>
                        <th>joined</th>
                        <th>Sessions</th>
                        {/* <th>Last Message</th> */}
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.recentUsers.map((user: any, i) => (
                        <tr key={i}>
                          <th>
                            <img className="peoples_img" src={user.profile_pic} />
                          </th>
                          <th>
                            {user.first_name} {user.last_name}
                          </th>
                          <th>{user.joining_date}</th>
                          <th>{user.joined}</th>
                          <th>{user.session}</th>
                          {/* <th>show me</th> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="msg_view">
                    <Link to="/leadgen">
                      View All Users{" "}
                      <i className="fa fa-caret-up" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/*{this.state.chart && (*/}
            {/*    <div className="p-3 col-md-12 mt-2 mr-2">*/}
            {/*        <div className="card p-2">*/}
            {/*            <div className="card-header">*/}
            {/*                <h4 className="ml-4">Header</h4>*/}
            {/*            </div>*/}
            {/*            <div className="card-body">*/}
            {/*                <LineChart chart={this.state.chart} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{!this.state.chart && <span>Loading...</span>}*/}




            {/* <div className="col-md-6 mt_35">
              <div className="main_gender">
                <div className="gender_header">
                  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.034 0V2.02041H28.313L23.8669 6.23623C22.2929 4.62554 20.0981 3.62394 17.6735 3.62394C14.412 3.62394 11.566 5.43585 10.0882 8.10569C9.96161 8.10015 9.83438 8.0968 9.70638 8.0968C4.92924 8.0968 1.04279 11.9833 1.04279 16.7605C1.04279 21.1959 4.39332 24.8629 8.69614 25.3648V28.0497H5.67155V30.0702H8.69627V33H10.7167V30.0701H13.8734V28.0497H10.7167V25.3648C13.5493 25.0343 15.9688 23.3324 17.2917 20.9422C17.4183 20.9478 17.5455 20.9511 17.6734 20.9511C22.4506 20.9511 26.3371 17.0647 26.3371 12.2875C26.3371 10.6642 25.8879 9.1439 25.1079 7.84388L29.9367 3.26526V5.77461H31.9571V0H26.034ZM9.70651 23.4036C6.04345 23.4036 3.06333 20.4235 3.06333 16.7603C3.06333 13.24 5.8158 10.351 9.28157 10.1311C9.10426 10.8208 9.0099 11.5433 9.0099 12.2875C9.0099 16.1846 11.5965 19.4886 15.1431 20.5738C13.94 22.2839 11.9517 23.4036 9.70651 23.4036ZM16.0517 18.7301C13.17 18.0043 11.0304 15.3913 11.0304 12.2875C11.0304 11.602 11.1348 10.9404 11.3285 10.3178C14.2101 11.0435 16.3498 13.6567 16.3498 16.7604C16.3498 17.4459 16.2453 18.1075 16.0517 18.7301ZM18.0985 18.9167C18.2758 18.227 18.3702 17.5046 18.3702 16.7603C18.3702 12.8632 15.7835 9.55924 12.2371 8.47404C13.4401 6.76397 15.4285 5.64422 17.6736 5.64422C21.3368 5.64422 24.3169 8.62434 24.3169 12.2875C24.3167 15.8078 21.5643 18.6969 18.0985 18.9167Z" fill="#535353" />
                  </svg>
                  <h4>Gender</h4>
                </div>
                <div className="gender_body">
                  <Chart series={[70]} options={this.state.genderOptions} type="radialBar"
                    width={500}
                    height={320} />
                </div>
                <div className="gender_footer mt_20">
                  <div className="male">
                    <div className="gender_footer_img">
                      <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                          <circle cx="7.93927" cy="6.93927" r="5.41476" transform="rotate(0.203451 7.93927 6.93927)" stroke="#049780" stroke-width="3" />
                          <path d="M7.48013 21.775C7.86927 22.1669 8.50243 22.1691 8.89434 21.78L15.2809 15.4387C15.6728 15.0495 15.675 14.4164 15.2859 14.0245C14.8967 13.6326 14.2636 13.6303 13.8717 14.0194L8.19477 19.6562L2.55803 13.9793C2.1689 13.5874 1.53574 13.5851 1.14383 13.9743C0.75192 14.3634 0.749672 14.9965 1.13881 15.3885L7.48013 21.775ZM7.21964 12.6489L7.18975 21.0668L9.18974 21.0739L9.21963 12.656L7.21964 12.6489Z" fill="#049780" />
                        </g>
                      </svg>
                    </div>
                    <div className="male_gender_text">
                      <p className="male_text">Male</p>
                      <h4>26,000</h4>
                    </div>
                  </div>
                  <div className="male">
                    <div className="gender_footer_img">
                      <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                          <circle cx="7.49962" cy="7.50038" r="5.97389" transform="rotate(0.203451 7.49962 7.50038)" stroke="#049780" stroke-width="3" />
                          <rect x="6.57468" y="13.8804" width="2.19163" height="8.03599" fill="#049780" />
                          <rect x="4.38305" y="17.5331" width="6.5749" height="1.46109" fill="#049780" />
                        </g>
                      </svg>

                    </div>
                    <div className="male_gender_text">
                      <p className="male_text">Female</p>
                      <h4>26,000</h4>
                    </div>
                  </div>
                  <div className="male">
                    <div className="gender_footer_img">
                      <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                          <circle cx="7.49962" cy="7.50038" r="5.97389" transform="rotate(0.203451 7.49962 7.50038)" stroke="#049780" stroke-width="3" />
                          <rect x="6.57468" y="13.8804" width="2.19163" height="8.03599" fill="#049780" />
                          <rect x="4.38305" y="17.5331" width="6.5749" height="1.46109" fill="#049780" />
                        </g>
                      </svg>

                    </div>
                    <div className="male_gender_text">
                      <p className="male_text">Other</p>
                      <h4>26,000</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mt_35">
              <div className="main_gender">
                <div className="gender_header language_padding">
                  <div className="language_icon">
                    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.20091 0H7.82039L14 17H11.9543L10.1644 12.0197H3.81431L2.04566 17H0L6.20091 0ZM9.7382 10.3437L7.01065 2.49014L4.19787 10.3437H9.7382Z" fill="#535353" />
                    </svg>
                    <svg width="13" height="28" viewBox="0 0 13 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M12 1L1 27" stroke="#535353" />
                    </svg>

                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 1.30031H15.5625V15L15.1172 14.7214V14.7446C14.5234 12.4845 13.4531 10.8514 11.9062 9.8452C11.5625 10.4025 11.0859 10.8359 10.4766 11.1455C9.88281 11.4551 9.1875 11.6099 8.39062 11.6099C7.95312 11.6099 7.50781 11.517 7.05469 11.3313C6.60156 11.1455 6.14844 10.8824 5.69531 10.5418C5.25781 10.2012 4.83594 9.79102 4.42969 9.31114C4.02344 8.83127 3.64062 8.30495 3.28125 7.7322C2.85938 7.02012 2.51562 6.17647 2.25 5.20124C1.98437 4.22601 1.78906 3.18112 1.66406 2.06656L2.46094 2.39164C2.67969 3.45975 2.97656 4.42724 3.35156 5.29412C3.72656 6.16099 4.16406 6.91177 4.66406 7.54644C5.16406 8.16564 5.71094 8.65325 6.30469 9.00929C6.89844 9.34984 7.50781 9.52012 8.13281 9.52012C9.66406 9.52012 10.6875 9.07121 11.2031 8.17337C11.5469 7.5387 11.7187 6.91177 11.7187 6.29257C11.7187 6.07585 11.6953 5.84365 11.6484 5.59597C11.5391 5.20898 11.375 4.86068 11.1563 4.55108C10.9531 4.24149 10.7656 4.00929 10.5937 3.85449C10.3281 3.66873 10.0625 3.54489 9.79687 3.48297C9.53125 3.40557 9.26562 3.42105 9 3.52941C9.28125 3.90093 9.42188 4.24923 9.42188 4.5743C9.42188 4.85294 9.33594 5.12384 9.16406 5.387C9.00781 5.65016 8.78906 5.87461 8.50781 6.06037C8.07031 6.40093 7.54687 6.52477 6.9375 6.43189C6.35937 6.32353 5.90625 6.03715 5.57812 5.57275C5.42188 5.27864 5.34375 4.9613 5.34375 4.62074C5.34375 4.24923 5.4375 3.90093 5.625 3.57585C5.8125 3.23529 6.04688 2.95666 6.32812 2.73994C7.07812 2.2291 7.8125 1.97368 8.53125 1.97368C8.875 1.97368 9.22656 2.02786 9.58594 2.13622C10.0859 2.3065 10.5625 2.56192 11.0156 2.90248C11.4844 3.24303 11.8828 3.67647 12.2109 4.20279C12.6172 4.93034 12.8203 5.75077 12.8203 6.66409C12.8203 7.31424 12.7266 7.98762 12.5391 8.68421L14.25 10.2167V1.30031H1.3125L0 0H16.6875L18 1.30031Z" fill="#535353" />
                    </svg>

                  </div>
                  <h4>Language</h4>
                </div>
                <div className="gender_body">
                <Chart series={[40]} options={this.state.languageOptions} type="radialBar"
                    width={500}
                    height={320} />
                </div>
                <div className="gender_footer mt_20">
                  <div className="male">
                    <div className="gender_footer_img">
                      <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.8" d="M4.87215 0H6.1446L11 13H9.39269L7.9863 9.19155H2.99696L1.60731 13H0L4.87215 0ZM7.65145 7.90986L5.50837 1.90423L3.29833 7.90986H7.65145Z" fill="#06A48B" />
                      </svg>

                    </div>
                    <div className="male_gender_text">
                      <p className="male_text">English</p>
                      <h4>26,000</h4>
                    </div>
                  </div>
                  <div className="male">
                    <div className="gender_footer_img">
                      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.8" d="M14 1.04025H12.1042V12L11.7578 11.7771V11.7957C11.296 9.98762 10.4635 8.68111 9.26042 7.87616C8.99305 8.32198 8.6224 8.66873 8.14844 8.91641C7.68663 9.16409 7.14583 9.28793 6.52604 9.28793C6.18576 9.28793 5.83941 9.21362 5.48698 9.06502C5.13455 8.91641 4.78212 8.70588 4.42969 8.43344C4.08941 8.16099 3.76128 7.83282 3.44531 7.44892C3.12934 7.06502 2.8316 6.64396 2.55208 6.18576C2.22396 5.6161 1.9566 4.94118 1.75 4.16099C1.5434 3.38081 1.39149 2.54489 1.29427 1.65325L1.91406 1.91331C2.0842 2.7678 2.3151 3.5418 2.60677 4.23529C2.89844 4.92879 3.23871 5.52941 3.6276 6.03715C4.01649 6.53251 4.44184 6.9226 4.90365 7.20743C5.36545 7.47988 5.83941 7.6161 6.32552 7.6161C7.51649 7.6161 8.3125 7.25697 8.71354 6.5387C8.9809 6.03096 9.11458 5.52941 9.11458 5.03406C9.11458 4.86068 9.09635 4.67492 9.0599 4.47678C8.97483 4.16718 8.84722 3.88854 8.67708 3.64087C8.5191 3.39319 8.37326 3.20743 8.23958 3.08359C8.03299 2.93499 7.82639 2.83591 7.61979 2.78638C7.41319 2.72446 7.2066 2.73684 7 2.82353C7.21875 3.12074 7.32812 3.39938 7.32812 3.65944C7.32812 3.88235 7.26128 4.09907 7.1276 4.3096C7.00608 4.52012 6.83594 4.69969 6.61719 4.8483C6.27691 5.12074 5.86979 5.21981 5.39583 5.14551C4.94618 5.05882 4.59375 4.82972 4.33854 4.4582C4.21701 4.22291 4.15625 3.96904 4.15625 3.69659C4.15625 3.39938 4.22917 3.12074 4.375 2.86068C4.52083 2.58824 4.70312 2.36533 4.92188 2.19195C5.50521 1.78328 6.07639 1.57895 6.63542 1.57895C6.90278 1.57895 7.17621 1.62229 7.45573 1.70898C7.84462 1.8452 8.21528 2.04954 8.56771 2.32198C8.93229 2.59443 9.24219 2.94118 9.49739 3.36223C9.81337 3.94427 9.97135 4.60062 9.97135 5.33127C9.97135 5.85139 9.89844 6.39009 9.7526 6.94737L11.0833 8.17338V1.04025H1.02083L0 0H12.9792L14 1.04025Z" fill="#06A48B" />
                      </svg>


                    </div>
                    <div className="male_gender_text">
                      <p className="male_text">Bangla</p>
                      <h4>26,000</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="main_language">
                <div className="language_header">

                </div>
              </div>
            </div> */}
          </div>





          <div className="row">
            <div className="col-md-3">
              <Doughnut data={data} options={options} />
            </div>
            <div className="col-md-3">
              <Doughnut data={data1} options={options1} />
            </div>
          </div>

          {/* <ReactWeather
                        forecast="today"
                        apikey="02b236a2b574c000f3dc1fec62038044"
                        type="geo"
                        lat="48.1351"
                        lon="11.5820"
                    /> */}
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
