// export const apiPrefix = 'http://localhost:8000/api/'
export const apiPrefix = 'https://apiroot.rabibgalib.com/api/'
// export const apiPrefix = 'https://e0de-103-197-155-35.ngrok.io/api/'
export const baseTitle = 'Automation Service'
export const logo = "https://res.cloudinary.com/chatfuel/image/upload/v1584542005/Profile_Picture_1.jpg"



export class TableCSVExporter {
  table: any;
  rows: any;
  constructor(table, includeHeaders = true) {
    this.table = table;
    this.rows = Array.from(table.querySelectorAll("tr"));

    if (!includeHeaders && this.rows[0].querySelectorAll("th").length) {
      this.rows.shift();
    }
  }

  convertToCSV() {
    const lines = [];
    const numCols = this._findLongestRowLength();

    for (const row of this.rows) {
      let line = "";

      for (let i = 0; i < numCols; i++) {
        if (row.children[i] !== undefined) {
          line += TableCSVExporter.parseCell(row.children[i]);
        }

        line += i !== numCols - 1 ? "," : "";
      }

      lines.push(line);
    }

    return lines.join("\n");
  }

  _findLongestRowLength() {
    return this.rows.reduce(
      (l, row) => (row.childElementCount > l ? row.childElementCount : l),
      0
    );
  }

  static parseCell(tableCell) {
    let parsedValue = tableCell.textContent;

    // Replace all double quotes with two double quotes
    parsedValue = parsedValue.replace(/"/g, `""`);

    // If value contains comma, new-line or double-quote, enclose in double quotes
    parsedValue = /[",\n]/.test(parsedValue) ? `"${parsedValue}"` : parsedValue;

    return parsedValue;
  }
}

export const dateRange = (days) => {
  let date = new Date();
  let startDate = new Date(date.setDate(date.getDate() - days));
  let endDate = new Date();

  return { startDate, endDate };
};
